import React from "react";

const Services = () => {
	// services details
	const services = [
		{
			name: "API Development & Integration",
			desc: "Designing and implementing RESTful or GraphQL APIs. Integrating third-party services, databases, or microservices into existing platforms.",
			icon: "fas fa-server",
		},
		{
			name: "Application   Development",
			desc: "Building dynamic, responsive web applications using MongoDB, Express.js, React.js, and Node.js and other modern technologies.",
			icon: "fas fa-desktop",
		},
		{
			name: "Responsive & Mobile-First Design",
			desc: "Ensuring the UI adapts seamlessly to different screen sizes (mobile, tablet, desktop). Utilizing CSS frameworks like Tailwind CSS, Material UI, or custom CSS to ensure a mobile-first approach.",
			icon: "fas fa-pencil-ruler",
		},
		{
			name: "Database and Design Management",
			desc: "Designing, maintaining, and optimizing SQL and NoSQL databases. Ensuring data security and integrity.",
			icon: "fas fa-database",
		},
		{
			name: "Performance Optimization",
			desc: "Analyzing and improving application performance to reduce load times and enhance user experience. Implementing lazy loading, caching, and optimizing database queries.",
			icon: "fas fa-bolt",
		},
		{
			name: "Maintenance & Support",
			desc: "Providing ongoing support for bug fixing, performance improvements, and feature updates.",
			icon: "fas fa-toolbox",
		},
	];

	return (
		<section id="services" className="section bg-light">
			<div className="container">
				{/* Heading */}
				{/* <p className=" text-center mb-2 wow fadeInUp">
					<span className="bg-primary text-dark px-2">What I Do?</span>
				</p> */}
				<h2 className="text-10 fw-400 text-center mb-5 wow fadeInUp">
					What I Do?
				</h2>
				{/* Heading end*/}
				<div className="row gy-5 mt-5">
					{services.length > 0 &&
						services.map((service, index) => (
							<div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
								<div className="featured-box text-center px-md-4">
									<div className="featured-box-icon text-primary text-13">
										{" "}
										<i className={service.icon} />
									</div>
									<h3 className="text-6 fw-600 mb-3 custom-service-heading">
										{service.name}
									</h3>
									<p className="text-muted mb-0">{service.desc} </p>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default Services;
