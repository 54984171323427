import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
	const educationDetails = [
		{
			yearRange: "2015 - 2019",
			title: "Bachelor of Science in Computer Science",
			place: "Comsats University of Information and technology",
			desc: " Major Core CS Courses: OOP, Data Mining, Artificial Intelligence, Compiler Construction, Data Structures, Database Design, and many others.",
		},
		{
			yearRange: "2013 - 2015",
			title: "Intermediate in Computer Science",
			place: "Punjab Group of Colleges",
			desc: "Acquired foundational knowledge and practical skills through hands-on projects and assignments, providing a solid base for further development in the field.",
		},
	];

	const experienceDetails = [
		{
			yearRange: "2020 Oct - Current",
			title: "Full Stack Engineer",
			place: "Professional Freelancer on Upwork",
			desc: [
				"Leveraged full-stack development skills to design and implement software solutions throughout the entire lifecycle, enhancing business efficiency for diverse client projects.",
				"Identified and optimized project components to improve scalability, robustness, and code quality, ensuring long-term performance and maintainability.",
				"Troubleshot and resolved both simple and complex technical issues, delivering timely solutions and ensuring smooth operation across various client projects.",
			],
		},
		{
			yearRange: "2022 Mar - 2023 Apr",
			title: "Software Engineer",
			place: "Digiance LLC",
			desc: [
				`Engineered and developed full-stack solutions using React.js and other modern JavaScript frameworks, contributing to the design and functionality of projects.`,
				`Proactively identified areas for system improvement, conducting regular performance monitoring to enhance overall business efficiency and system effectiveness.`,
				`Designed and implemented CI/CD pipelines using Jenkins, Docker, and custom scripts, streamlining deployment processes for faster and more efficient app releases.`,
				`Optimized front-end performance by modifying JSX, JavaScript, and CSS, resulting in significantly faster page loading times and enhanced user experience.`,
			],
		},
		{
			yearRange: "2019 Sep - 2020 Sep",
			title: "Associate Software Engineer",
			place: "Devsinc Private Limited,  Lahore,  Pakistan",
			desc: [
				`Contributed to full-stack development of projects using JavaScript and Ruby on Rails, delivering robust and scalable web applications.`,
				`Learned and applied optimized development techniques under the guidance of a senior team lead, contributing to the development of high-performing, scalable applications.`,
				`Utilized strategic methodologies to analyze client databases, developing detailed mapping specifications for seamless data integration and improved functionality.`,
			],
		},
	];

	const skills = {
		frontend: [
			"ReactJS",
			"Javascript",
			"React Native",
			"Next.js",
			"Redux",
			"React Hooks",
			"Material UI",
			"ES6+",
			"TypeScript",
		],
		backend: [
			"NodeJS",
			"ExpressJS",
			"GraphQL",
			"REST API design",
			"WebSockets",
			"Socket.io",
			"ElasticSearch",
		],
		databaseAndCloudDevops: [
			"MongoDB",
			"MySQL",
			"PostgresQL",
			"Prisma ORM",
			"Sequelize",
			"Docker",
			"Vercel",
			"Github",
			"Amazon Web Services",
		],
	};

	return (
		<section id="resume" className="section">
			<div className="container">
				{/* Heading */}
				{/* <p className=" text-center mb-2 wow fadeInUp">
					<span className="bg-primary text-dark px-2">Resume</span>
				</p> */}
				<h2 className="text-10 fw-400 text-center mb-5 wow fadeInUp">
					A summary of My Resume
				</h2>
				{/* Heading end*/}
				<div className="row g-5 mt-5">
					{/* My Education */}
					<div className="col-lg-6 wow fadeInUp">
						<h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
						<div className="border-start border-2 border-primary ps-3">
							{educationDetails.length > 0 &&
								educationDetails.map((value, index) => (
									<div key={index}>
										<h3 className="text-5">{value.title}</h3>
										<p className="mb-2">
											{value.place} / {value.yearRange}
										</p>
										<p className="text-muted">{value.desc}</p>
										<hr className="my-4" />
									</div>
								))}
						</div>
					</div>
					{/* My Experience */}
					<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
						<h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
						<div className="border-start border-2 border-primary ps-3">
							{experienceDetails.length > 0 &&
								experienceDetails.map((value, index) => (
									<div key={index}>
										<h3 className="text-5">{value.title}</h3>
										<p className="mb-2">
											{value.place} / {value.yearRange}
										</p>
										<ul>
											{value.desc.map((point) => (
												<li className="text-muted">{point}</li>
											))}
										</ul>
										<hr className="my-4" />
									</div>
								))}
						</div>
					</div>
				</div>
				{/* My Skills */}
				<h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp text-center">
					My Skills
				</h2>
				<div className="row gx-5">
					<div className="col-12 col-md-6 col-lg-4 wow fadeInUp">
						<h2 className="text-5 fw-400 mb-4 pb-2 mt-5 wow fadeInUp text-center">
							Frontend
						</h2>
						<div className="row gx-5">
							{skills.frontend.map((skill, index) => (
								<div
									className="col-6 col-sm-4 col-md-4 skill-custom-tab"
									key={index}
								>
									{" "}
									<span className="text-2 skill-element bg-primary custom-skill-text">
										{skill}
									</span>{" "}
								</div>
							))}
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4  wow fadeInUp">
						<h2 className="text-5 fw-400 mb-4 pb-2 mt-5 wow fadeInUp text-center">
							Backend
						</h2>
						<div className="row">
							{skills.backend.map((skill, index) => (
								<div
									className="col-6 col-sm-4 col-md-4 skill-custom-tab "
									key={index}
								>
									{" "}
									<span className="text-2 skill-element bg-primary custom-skill-text">
										{skill}
									</span>{" "}
								</div>
							))}
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4 wow fadeInUp">
						<h2 className="text-5 fw-400 mb-4 pb-2 mt-5 wow fadeInUp text-center">
							Database and Devops
						</h2>
						<div className="row">
							{skills.databaseAndCloudDevops.map((skill, index) => (
								<div
									className="col-6 col-sm-4 col-md-4  skill-custom-tab"
									key={index}
								>
									{" "}
									<span className="text-2 skill-element bg-primary custom-skill-text">
										{skill}
									</span>{" "}
								</div>
							))}
						</div>
					</div>
				</div>
				{/* <div className="row gx-5">
					{skills.length > 0 &&
						skills.map((skill, index) => (
							<div className="col-md-6 wow fadeInUp" key={index}>
								<p className="fw-500 text-start mb-2">
									{skill.name}{" "}
									<span className="float-end">{skill.percent}%</span>
								</p>
								<div className="progress progress-sm mb-4">
									<div
										className="progress-bar"
										role="progressbar"
										style={{ width: skill.percent + "%" }}
										aria-valuenow={skill.percent}
										aria-valuemin={0}
										aria-valuemax={100}
									/>
								</div>
							</div>
						))}
				</div> */}
				<p className="text-center mt-5 wow fadeInUp">
					<a
						className="btn btn-outline-dark shadow-none rounded-0"
						href={resumeFile}
						download
					>
						Download CV
					</a>
				</p>
			</div>
		</section>
	);
};

export default Resume;
