import React from "react";
const AboutUs = () => {
	return (
		<section id="about" className="section">
			<div className="container">
				{/* Heading */}
				{/* <p className="text-center mb-2 wow fadeInUp">
					<span className="bg-primary text-dark px-2">About Me</span>
				</p> */}
				<h2 className="text-10 fw-400 text-center mb-5 wow fadeInUp">
					Know Me More
				</h2>
				{/* Heading end*/}
				<div className="row">
					<div className="col-lg-8 text-center text-lg-start wow fadeInUp">
						<h2 className="text-8 fw-400 mb-3">
							I'm{" "}
							<span className="fw-700 border-bottom border-3 border-primary">
								Abubakar Akram
							</span>
						</h2>
						<p className="text-4">
							About With five years of dedicated experience as a full-stack
							developer, I am well-versed in an array of cutting-edge
							technologies including the MERN stack, React, NextJS, TypeScript,
							JavaScript, and NodeJS. My expertise lies in architecting,
							building, and deploying high-performance, secure, and scalable web
							applications tailored to meet dynamic business needs.<br></br>
							<br></br> I am proficient in Microservices architecture and
							continuous integration/continuous delivery (CI/CD) practices, with
							substantial experience in cloud platforms such as AWS and Azure.
							Known for my strong problem-solving capabilities and unwavering
							commitment, I consistently drive projects to successful
							completion, ensuring robust solutions that deliver tangible
							results.
							<br></br> <br></br> A quick learner with a passion for adopting
							new technologies, I excel in both collaborative and independent
							work environments. Currently expanding my expertise in Machine
							Learning and AI, aiming to build intelligent, cutting-edge web
							applications that address users' evolving demands.
						</p>
					</div>
					<div
						className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
						data-wow-delay="0.2s"
					>
						<div className="featured-box style-4">
							<div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle">
								<span className="wow heartBeat" data-wow-delay="1.3s">
									5
								</span>
							</div>
							<h3 className="text-7 wow rubberBand" data-wow-delay="2s">
								Years of <span className="fw-700">Experiance</span>
							</h3>
						</div>
					</div>
				</div>
				<div className="row gy-3 mt-4">
					<div className="col-6 col-lg-3 wow fadeInUp">
						<p className="text-muted fw-500 mb-0">Name:</p>
						<p className="text-4 text-dark fw-600 mb-0">Abubakar Akram</p>
					</div>
					<div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
						<p className="text-muted fw-500 mb-0">Email:</p>
						<p className="text-4 fw-600 mb-0">
							<a className="link-dark" href="mailto:dev@abubakarakram.com">
								dev@abubakarakram.com
							</a>
						</p>
					</div>
					{/* <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
						<p className="text-muted fw-500 mb-0">Date of birth:</p>
						<p className="text-4 text-dark fw-600 mb-0">11 November, 1987</p>
					</div> */}
					<div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
						<p className="text-muted fw-500 mb-0">From:</p>
						<p className="text-4 text-dark fw-600 mb-0">Lahore, Pakistan.</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
