import React from "react";

const Roles = () => {
	// services details
	const roles = [
		{
			name: "Architecting Solutions",
			desc: "A Team lead responsible for structure of both client-side and server-side applications, ensuring smooth integration between front-end and back-end systems.",
			icon: "fas fa-sitemap",
		},
		{
			name: "Leadership",
			desc: "Lead development teams, offer guidance, and ensure that junior developers follow coding standards and best practices.",
			icon: "fas fa-crown",
		},
		{
			name: "Collaboration & Communication",
			desc: "As an Experienced developer, work directly with clients, helping to scope projects, estimate timelines, and offer technical consultation.",
			icon: "fas fa-comment-dots",
		},
	];

	return (
		<section id="roles" className="section bg-light">
			<div className="container">
				{/* Heading */}
				{/* <p className=" text-center mb-2 wow fadeInUp">
					<span className="bg-primary text-dark px-2">Roles?</span>
				</p> */}
				<h2 className="text-10 fw-400 text-center mb-5 wow fadeInUp">
					My Responsibilities As Lead Developer
				</h2>
				{/* Heading end*/}
				<div className="row gy-5 mt-5">
					{roles.length > 0 &&
						roles.map((role, index) => (
							<div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
								<div className="featured-box text-center px-md-4">
									<div className="featured-box-icon text-primary text-13">
										{" "}
										<i className={role.icon} />
									</div>
									<h3 className="text-6 fw-600 mb-3 custom-service-heading">
										{role.name}
									</h3>
									<p className="text-muted mb-0">{role.desc} </p>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default Roles;
